<template>
  <el-carousel indicator-position="none" height="800px">
    <el-carousel-item v-for="item in imglist" :key="item.key">
      <section class="box bg" v-lazy:background-image="item.imgsrc">
        <section class="container">
          <section :class="['left', {'w-576':item.key==='lunbo2', 'w-520':item.key==='lunbo4'}]">
            <h3>{{item.title}}</h3>
            <strong>{{item.desc1}}</strong>
            <strong>{{item.desc2}}</strong>
            <a :class="['btn', item.key]" :href="item.path">查看详情</a>
          </section>
        </section>
      </section>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import CountTo from 'vue-count-to'
export default {
  components: { CountTo },
  name: 'wlCarousel',
  props: {
    isNeedInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imglist: [
        {
          key: 'lunbo4',
          imgsrc: '/static/img/home/lunbo4.png',
          title: '高校实验室安全规范管理平台',
          desc1: '贴合高校实际需求  符合教育部要求',
          desc2: '实现规范化、常态化管理，切实保障实验室安全',
          path: '/product/standard',
        },
        {
          key: 'lunbo1',
          imgsrc: '/static/img/home/lunbo1.png',
          title: '实验室综合管理平台',
          desc1: '对实验室人员、仪器、试剂、危险源、环境等全方位管理',
          desc2: '让实验室管理更便捷、更安全',
          path: '/product/synthetical',
        },
        {
          key: 'lunbo2',
          imgsrc: '/static/img/home/lunbo2.png',
          title: '实验室安全分类分级管理系统',
          desc1: '危险源风险辨识',
          desc2: '分级管理、分类施策、动态调整、精准防范、安全闭环',
          path: '/product/classify',
        },
        {
          key: 'lunbo3',
          imgsrc: '/static/img/home/lunbo3.png',
          title: '实验室危化品管理系统',
          desc1: '危化品全生命周期管理',
          desc2: '库存可查、来源可循、去向可追、状态可控',
          path: '/product/hazardous',
        },
      ],
      showIndex: 0,
      infoList: [
        { title: '专注', desc: '实验室信息化管理' },
        { title: '大', desc: '产品线', startVal: 0, endVal: 3 },
        { title: '年', desc: '实验室信息化服务经验', startVal: 0, endVal: 15 },
        { title: '+', desc: '高校、科研单位的成功选择', startVal: 0, endVal: 300 },
      ],
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 800px;
  position: relative;
}
.container {
  height: 800px;
  padding-top: 268px;
  .left {
    width: 480px;
    z-index: 3;
  }
}
.w-576 {
  width: 576px !important;
  h3 {
    margin-bottom: 46px;
  }
}
.w-520 {
  width: 520px !important;
  .btn {
    margin-top: 95px;
  }
}
.w-360 {
  width: 360px !important;
}
.btn {
  width: 160px;
  height: 48px;
  background: #308dff;
  box-shadow: 1px 8px 20px 1px rgba(27, 117, 229, 0.4);
  border-radius: 24px;
  font-size: 20px;
  color: #fff;
  line-height: 48px;
  z-index: 4;
  margin-top: 76px;
  display: inline-block;
  text-align: center;
}
strong {
  font-size: 24px;
  display: block;
  color: #6d6d6d;
  line-height: 46px;
}
h3 {
  margin-bottom: 40px;
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #262626;
}
section {
  text-align: left;
}
.lunbo1 {
  background: #308dff;
}
.lunbo2 {
  background: #3b98ff;
}
.lunbo3 {
  background: #4472c4;
}
</style>
